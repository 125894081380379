export default {
  'unexpected-error': 'Unerwarteter Fehler',
  'copied-to-clipboard': 'In die Zwischenablage kopiert',
  header: {
    title: 'Überprüfen und analysieren Sie die DNS-Einträge Ihrer Domain',
    'logo-alt': 'IntoDNS',
    'search-button-aria-label': 'Domain analysieren',
    analize: 'Analysieren',
    loading: 'Laden',
    'search-bar-aria-label': 'Domain-Suchleiste',
    'change-language': 'Sprache ändern',
    'select-language': 'Sprache \'{lang}\' auswählen',
    'selected-language-flag': 'Ausgewählte Sprachflagge von \'{lang}\'',
    'alternative-language-flag': 'Alternative Sprachflagge von \'{lang}\''
  },
  footer: {
    title: 'Benötigen Sie Hosting für Ihre Domain?',
    description:
            'Bei Raiola Networks bieten wir Hosting für Ihre Domain an. In Bezug auf DNS-Server sind wir gut aufgestellt, da wir redundante und verteilte DNS-Server weltweit für die bei uns gehosteten Dienste haben',
    'discover-it': 'Entdecken Sie es!',
    'visit-our-blog': 'Besuchen Sie unseren Blog!',
    'raiola-server-alt': 'Raiola-Server',
    'raiola-team-alt': 'Raiola Networks Systemteam',
    'api-docs': 'API-Dokumentation',
    'go-to-docs-api-version': 'Weiter zu V{version}'
  },
  landing: {
    domains: 'Domains',
    'dns-records': 'DNS-Einträge',
    'a-records': 'A-Einträge',
    'ns-records': 'NS-Einträge',
    'mx-records': 'MX-Einträge',
    'head-title': 'Überprüfen und analysieren Sie die DNS-Einträge Ihrer Domain',
    'meta-description': 'Verwenden Sie IntoDNS, um DNS-Einträge einer Domain zu scannen, zu überprüfen und Berichte zu erstellen. Es ist ein ideales Analysetool für Webadministratoren',
    'main-title': 'Was ist IntoDNS?',
    'main-description':
            '<span class="font-bold">IntoDNS</span> ist ein <span class="font-bold">DNS</span>-Diagnose- und Abfrage-Tool für <span class="font-bold">DNS-Einträge</span>, das Ihnen den Status Ihrer <span class="font-bold">Domain</span> und ihrer <span class="font-bold">DNS-Einträge</span> anzeigt.<br><br><span class="font-bold">IntoDNS</span> ist besonders für Webadministratoren gedacht, die die <span class="font-bold">DNS-Konfiguration</span> ihrer <span class="font-bold">Domains</span> überprüfen müssen oder <span class="font-bold">Drittanbieter-Domains</span> überprüfen möchten',
    'how-works-title': 'Wie funktioniert es?',
    features: {
      'dns-scan': {
        title: 'DNS-Scan',
        description:
                    'Wir scannen die DNS-Server der gewünschten Domain und zeigen relevante Informationen zu den Einträgen an'
      },
      'dns-check': {
        title: 'DNS-Überprüfung',
        description:
                    'Wir überprüfen, ob die im DNS-Scan erhaltenen Daten korrekt und zuverlässig sind'
      },
      'dns-info': {
        title: 'DNS-Bericht',
        description:
                    'Wir erstellen einen Bericht mit den Daten, die von den DNS-Servern Ihrer Domain erhalten wurden'
      }
    },
    'stats-title': 'IntoDNS-Statistiken',
    'stats-subtitle':
            'Unten sehen Sie einige Nutzungsstatistiken von IntoDNS',
    'api-title': 'IntoDNS API',
    'api-description': 'Wenn Sie die Technologie von IntoDNS in Ihren Projekten verwenden möchten, können Sie Ihre Programme und Dienste mit unserer API integrieren. Sie haben Zugriff auf alle Funktionen von IntoDNS, um DNS-Analysen und -Tests zu automatisieren.',
    'api-pre-go-to': 'Unsere API ist öffentlich und kostenlos zugänglich. Wenn Sie mehr wissen möchten, gehen Sie zu:',
    'go-to-docs-api-version': 'API-Dokumentation V{version}'

  },
  analysis: {
    'copy-section-link': 'Link zur Sektion kopieren',
    'link-copied': 'Link kopiert',
    'aria-label-copy-to-dns-hosts-section': 'Link zur Sektion "DNS-Hosts Spur" kopieren',
    'aria-label-copy-to-general-tests-section': 'Link zur Sektion "Allgemeine Tests" kopieren',
    'aria-label-copy-to-ns-records-section': 'Link zur Sektion "NS-Einträge" kopieren',
    'aria-label-copy-to-soa-record-section': 'Link zur Sektion "SOA-Eintrag" kopieren',
    'aria-label-copy-to-target-records-section': 'Link zur Sektion "A / AAAA / CNAME Einträge" kopieren',
    'aria-label-copy-to-mx-records-section': 'Link zur Sektion "MX-Einträge" kopieren',
    'aria-label-copy-to-txt-records-section': 'Link zur Sektion "TXT-Einträge" kopieren',
    'aria-label-copy-to-spf-record-section': 'Link zur Sektion "SPF-Eintrag" kopieren',
    'aria-label-copy-to-dmarc-record-section': 'Link zur Sektion "DMARC-Eintrag" kopieren',
    'aria-label-copy-to-caa-records-section': 'Link zur Sektion "CAA-Einträge" kopieren',
    'head-title': 'Analyse und Bericht für die Domain {domain}',
    'meta-description': 'Analyse und Bericht über die DNS-Einträge und Konfiguration der ausgewählten Domain',
    'view-datails': 'Details anzeigen',
    'hide-datails': 'Details ausblenden',
    yes: 'Ja',
    no: 'Nein',
    empty: 'Leer',
    'show-test-flags': 'Testflags anzeigen',
    'hide-test-flags': 'Testflags ausblenden',
    'visible-details': 'Sichtbare Details',
    'hidden-details': 'Verborgene Details',
    tests: 'Tests',
    'ns-records': 'NS-Einträge',
    'soa-record': 'SOA-Eintrag',
    'mx-records': 'MX-Einträge',
    'spf-record': 'SPF-Eintrag',
    'dmarc-record': 'DMARC-Eintrag',
    'target-records': 'A / AAAA / CNAME Einträge',
    'txt-records': 'TXT-Einträge',
    'caa-records': 'CAA-Einträge',
    'results-of': 'Ergebnisse von {name}',
    'results-table': 'Ergebnistabelle',
    'trace-hosts': 'DNS-Hosts verfolgen',
    'go-to-section': 'Zur Sektion \'{section}\' gehen',
    records: 'Einträge',
    'record-status': 'Status',
    'record-details': 'Details',
    'analysis-result': 'Analyseergebnis',
    'record-test-flags': 'Testflags',
    'record-is-correctly-in-all-tests':
            'Keine Fehler bei diesem Eintrag erkannt',
    'parent-requested-for-analysis':
            'Autoritativer DNS-Server für DNS-Abfragen der Analyse ausgewählt',
    'irresolvable-host': 'Unlösbarer Host',
    'no-authoritative-hosts-found':
            'Es wurden keine autoritativen DNS-Server mit Informationen über die Domain gefunden. Wenn dies geschieht, versuchen Sie möglicherweise, eine nicht existierende Domain, eine nicht delegierte Subdomain oder eine nicht existierende Subdomain abzufragen',
    'test-successful': 'Tests abgeschlossen',
    'not-resolved': 'Nicht gelöst',
    category: 'Kategorie',
    status: 'Status',
    test: 'Test',
    information: 'Information',
    retry: 'Wiederholen',
    'unexpected-error-in-test':
            'Der Test konnte aufgrund eines unerwarteten Fehlers nicht abgeschlossen werden',
    'records-has-recommended-ttl': 'Die Einträge haben eine empfohlene TTL',
    'records-has-recommended-ttl-test-not-applicable-message': 'Dieser Test ist nicht anwendbar, da keine Einträge zur Bewertung vorhanden sind',
    'any-record-not-recovered': 'Kein Eintrag konnte wiederhergestellt werden',
    'zero-records-have-been-recovered': '0 Einträge wurden wiederhergestellt',
    'error-in-records-fetch': 'Fehler beim Abrufen der Einträge',
    ip: 'IP',
    ips: 'IPs',
    ipv4: 'IPv4',
    ipv6: 'IPv6',
    host: 'Host',
    target: 'Ziel',
    priority: 'Priorität',
    type: 'Typ',
    tag: 'Tag',
    flags: 'Flags',
    value: 'Wert',
    ptr: 'PTR',
    ttl: 'TTL',
    terms: 'Begriffe',
    'deploy-correct-tests': 'Richtige Tests anzeigen',
    'hide-correct-tests': 'Richtige Tests ausblenden',
    'there-are-n-correct-tests-hidden':
            'Es gibt <span class="font-bold">{nCorrectTests}</span> richtige Tests, die ausgeblendet wurden',
    'general-tests': 'Allgemeine Tests',
    'not-has-any-authority-host-correctly-configured-for-continue-analysis':
            'Kein korrekt konfigurierter autoritativer DNS-Server gefunden. Dies kann darauf zurückzuführen sein, dass kein in der Spur gefundener autoritativer DNS-Server auf DNS-Anfragen reagiert oder Informationen über die Domain hat',
    'unresolvable-host': 'Dieser Host löst keine DNS-Anfragen',
    'not-has-soa-record': 'Dieser DNS-Server hat keinen SOA-Eintrag für die Domain',
    'not-has-ns-record': 'Dieser DNS-Server hat keine NS-Einträge für die Domain',
    general: {
      'less-info': 'Weniger Informationen',
      'more-info': 'Mehr Informationen',
      'the-zone-should-not-be-transferable':
                'Die Zone sollte in keinem autoritativen DNS übertragbar sein (AXFR)',
      'the-authority-hosts-are-quivalent-to-ns-records':
                'Die autoritativen DNS sind den NS-Einträgen gleichwertig',
      'all-authority-hosts-has-same-soa':
                'Alle autoritativen DNS-Server müssen dasselbe SOA haben',
      'recommended-number-of-authority-hosts': 'Empfohlene Anzahl autoritativer DNS-Server',
      'dnssec-protection': 'DNSSEC-Schutz'
    },
    commons: {
      'records-target-has-resolvable-host-ips':
                'Die Einträge verweisen auf Hosts mit auflösbaren IPs'
    },
    NS: {
      'domain-ns-records': 'NS-Einträge der Domain',
      'ns-not-present-in-the-trace-have-the-same-soa-as-authority-hosts':
                'NS-Server, die nicht in den autoritativen DNS gefunden wurden, haben dasselbe SOA',
      'ns-not-present-in-the-trace-is-not-transferible':
                'NS-Server, die nicht in den autoritativen DNS gefunden wurden, sollten nicht übertragbar sein (AXFR)'
    },
    SOA: {
      'not-found': 'SOA-Eintrag nicht gefunden',
      'mname-term-is-real-authority-host': "Der Begriff 'mname' ist einer der autoritativen DNS-Server",
      'serial-term-is-correctly-configured': 'Seriennummer korrekt konfiguriert',
      'refresh-term-is-correctly-configured':
                'Aktualisierungsintervall korrekt konfiguriert',
      'retry-term-is-correctly-configured': 'Wiederholungsintervall korrekt konfiguriert',
      'expiry-term-is-correctly-configured': 'Ablaufintervall korrekt konfiguriert',
      'minimum-term-is-correctly-configured':
                'Mindestintervall korrekt konfiguriert',
      info: {
        mname:
                    'Primärer DNS-Server des DNS-Clusters und Hauptquelle der DNS-Zoneninformationen',
        rname: 'Kontakt-E-Mail des DNS-Zonenadministrators',
        serial:
                    'Nummer, die eine bestimmte Version der DNS-Zone identifiziert. Eine Zonentransfer behält diesen Wert bei',
        refresh:
                    'Zeit, die sekundäre DNS-Server warten sollten, bevor sie Änderungen vom primären DNS-Server anfordern',
        retry:
                    'Zeit, die DNS-Server warten sollten, bevor sie nach einem Fehler erneut eine Zonenaktualisierung anfordern',
        expiry:
                    'Zeit, nach der sekundäre Server, die ihre Zone nicht aktualisieren konnten, aufhören, DNS-Anfragen zu beantworten',
        minimum: 'Lebensdauer (TTL) für negative Cache-Antworten'
      }
    },
    'target-hosts': {
      'domain-a-aaaa-cname-records': 'A, AAAA und CNAME Einträge der Domain'
    },
    MX: {
      'domain-mx-records': 'MX-Einträge der Domain',
      'no-records-points-to-cname': 'Kein Eintrag verweist auf einen CNAME-Eintrag',
      'there-must-not-be-duplicated-records': 'Es dürfen keine doppelten Einträge vorhanden sein'
    },
    TXT: {
      'domain-txt-records': 'TXT-Einträge der Domain'
    },
    SPF: {
      includes: 'Einschlüsse',
      'redirect-to': 'Weiterleitung',
      'authorise-records-a': 'Autorisiert die A-Einträge der Domain',
      'authorise-records-mx': 'Autorisiert die MX-Einträge der Domain',
      'spf-recovered-but-error-in-process':
                'Der SPF-Eintrag konnte wiederhergestellt, aber nicht korrekt verarbeitet werden',
      'authorise-ptr': 'Autorisiert den PTR der IP der Domain',
      EXISTS: 'EXISTS',
      A: 'A',
      MX: 'MX',
      PTR: 'PTR',
      EXP: 'EXP',
      ALL: 'ALL',
      'not-found': 'Der SPF-Eintrag wurde nicht unter den TXT-Einträgen gefunden',
      'only-one-record-and-correct-format':
                'Es sollte nur einen Eintrag mit dem richtigen Format geben',
      'a-records-can-send-mails':
                'Hosts der A- und AAAA-Einträge sind autorisiert, E-Mails mit dem Domainnamen zu senden',
      'mx-records-can-send-mails':
                'Hosts der MX-Einträge sind autorisiert, E-Mails mit dem Domainnamen zu senden',
      'all-term-is-correctly-configured': "Der Begriff 'all' ist korrekt konfiguriert",
      'ptr-mechanism-use-not-recommended': 'PTR-Mechanismus wird nicht empfohlen',
      info: {
        'authorise-own-a-records':
                    'Autorisiert die entsprechenden Hosts der A / AAAA-Einträge des geprüften Wertes',
        'authorise-own-mx-records':
                    'Autorisiert die entsprechenden Hosts der MX-Einträge des geprüften Wertes',
        'authorise-own-ptr':
                    'Autorisiert den entsprechenden Host des PTR-Eintrags der IP des geprüften Wertes',
        a: 'Autorisiert die entsprechenden Hosts des A / AAAA-Eintrags',
        mx: 'Autorisiert die entsprechenden Hosts des MX-Eintrags',
        ptr: 'Autorisiert den entsprechenden Host des PTR-Eintrags',
        ip: 'Autorisiert die im Begriff selbst angegebene IPv4 oder IPv6',
        include: 'Überprüft den Host anhand zusätzlicher SPF-Einträge',
        redirect:
                    'Wenn kein vorheriger Begriff validiert, leitet die Überprüfung zum angegebenen Host weiter. Die Abfragedomain wird zur Umleitungsdomain, wenn sie angewendet wird',
        exp:
                    "Legt den TXT-Eintrag fest, der die Antwort enthält, wenn das Ergebnis 'fail' ist",
        exists:
                    'Ermöglicht beliebige Prüfungen durch einen Ausdruck, der das Format der zu bewertenden Hosts validiert',
        all:
                    "Standardaktion, wenn kein anderer Begriff validiert. Wenn es existiert, wird 'redirect' ignoriert"
      }
    },
    DMARC: {
      'not-found':
                'Der DMARC-Eintrag wurde nicht unter den TXT-Einträgen des Subdomains _dmarc.{domain} gefunden',
      'requested-domain':
                'Das Subdomain <span class="text-white">_dmarc.{domain}</span> wurde abgefragt',
      'only-one-record-and-correct-format':
                'Es sollte nur einen Eintrag mit dem richtigen Format geben',
      'policy-term-correctly-configured':
                'Richtlinienterm korrekt konfiguriert',
      'subdomain-policy-term-correctly-configured':
                'Richtlinienterm für Subdomains korrekt konfiguriert',
      'pct-term-correctly-configured':
                'PCT-Term korrekt konfiguriert',
      'rua-and-ruf-term-correctly-configured':
                'RUA- und RUF-Terme korrekt konfiguriert',
      'fo-term-correctly-configured':
                'FO-Term korrekt konfiguriert',
      'adkim-term-correctly-configured':
                'ADKIM-Term korrekt konfiguriert',
      'aspf-term-correctly-configured':
                'ASPF-Term korrekt konfiguriert',
      info: {
        p: 'Gibt die Richtlinie an, die befolgt werden soll, wenn die Validierung fehlschlägt',
        rua:
                    'URI oder URIs, an die aggregierte Berichte über die Validierungsaktivität gesendet werden',
        ruf:
                    'URI oder URIs, an die forensische Berichte über spezifische Validierungsfehler gesendet werden',
        adkim:
                    'Gibt das erforderliche Ausrichtungsniveau zwischen DKIM und der From-Domain an',
        aspf:
                    'Gibt das erforderliche Ausrichtungsniveau zwischen SPF und der From-Domain an',
        fo:
                    'Gibt die Art des Validierungsfehlers an, der erforderlich ist, um einen forensischen Bericht zu generieren',
        pct: "Prozentsatz der Anwendung von 'quarantine'- oder 'reject'-Richtlinien",
        rf: 'Gibt das Format an, das für die gesendeten forensischen Berichte verwendet wird',
        ri:
                    'Zeitintervall (maximal) zwischen aggregierten Berichten, das von den Empfänger-Servern gefordert wird',
        sp:
                    "Entspricht 'p', gilt jedoch nur für Subdomains. Wenn es nicht existiert, gilt 'p'"
      }
    },
    CAA: {
      'accepted-certifying-bodies': 'Akzeptierte Zertifizierungsstellen',
      'accept-all-certifying-bodies-message': 'Da keine Einschränkungsaufzeichnungen gefunden wurden (mit dem Tag \'issue\' oder \'issuewild\'), wird jede Zertifizierungsstelle akzeptiert',
      'deny-all-certifying-bodies-message': 'Da nur ein leerer Einschränkungsdatensatz vorhanden ist (mit dem Tag \'issue\' oder \'issuewild\'), wird jede Zertifizierungsstelle eingeschränkt'
    },
    accesibility: {
      'alt-email-icon': 'E-Mail-Symbol',
      'aria-label-copy-btn': 'Inhalts des Eintrags kopieren'
    },
    'what-is': "Was ist '{key}'?"
  },
  'feedback-form': {
    title: 'Vorschläge und Fehlerberichterstattung',
    description: 'Sie können uns Vorschläge senden oder uns über gefundene Fehler informieren',
    'email-contact': 'Kontakt-E-Mail',
    'email-place-holder': "example{'@'}example.com",
    'email-aria-label': 'Kontakt-E-Mail für das Formular für Vorschläge und Fehlerberichterstattung',
    'issue-description': 'Beschreibung des Vorschlags oder des gefundenen Fehlers',
    'issue-description-place-holder': 'Schreiben Sie hier Ihre Nachricht',
    'description-aria-label': 'Beschreibung des Vorschlags oder des gefundenen Fehlers für das Formular für Vorschläge und Fehlerberichterstattung',
    send: 'Senden',
    'head-title': 'Vorschläge und Fehlerberichterstattung',
    'meta-description': 'Formular zum Senden von Vorschlägen und Fehlerberichterstattung',
    'send-btn-aria-label': 'Senden-Schaltfläche für das Formular für Vorschläge und Fehlerberichterstattung',
    'go-to-feedback-btn-aria-label': 'Zum Formular für Vorschläge und Fehlerberichterstattung gehen'
  }
}
