export default {
  'unexpected-error': 'Error inesperado',
  'copied-to-clipboard': 'Copiado al portapapeles',
  header: {
    title: 'Comprueba y analiza los registros DNS de tu dominio',
    'logo-alt': 'IntoDNS',
    'search-button-aria-label': 'Analizar el dominio',
    analize: 'Analizar',
    loading: 'Cargando',
    'search-bar-aria-label': 'Barra de búsqueda de dominios',
    'change-language': 'Cambiar lenguaje',
    'select-language': 'Seleccionar lenguaje \'{lang}\'',
    'selected-language-flag': 'Bandera del lenguaje seleccionado de \'{lang}\'',
    'alternative-language-flag': 'Bandera de lenguaje alternativo de \'{lang}\''
  },
  footer: {
    title: '¿Necesitas Hosting para tu dominio?',
    description:
            'En Raiola Networks ofrecemos hosting para tu dominio, además en cuestiones de servidores DNS estamos bien cubiertos, ya que tenemos servidores DNS redundantes y distribuídos alrededor del mundo para los servicios alojados con nosotros',
    'discover-it': '¡Descúbrelo!',
    'visit-our-blog': '¡Visita nuestro blog!',
    'raiola-server-alt': 'Servidor de Raiola',
    'raiola-team-alt': 'Equipo de Sistemas de Raiola Networks',
    'api-docs': 'Documentación de la API',
    'go-to-docs-api-version': 'Ir a V{version}'

  },
  landing: {
    domains: 'Dominios',
    'dns-records': 'Registros DNS',
    'a-records': 'Registros A',
    'ns-records': 'Registros NS',
    'mx-records': 'Registros MX',
    'head-title': 'Comprueba y analiza los registros DNS de tu dominio',
    'meta-description': 'Utiliza IntoDNS para escanear, verificar y generar informes de registros DNS sobre un dominio. Es una herramienta de análisis ideal para administradores web',
    'main-title': '¿Qué es IntoDNS?',
    'main-description':
            '<span class="font-bold">IntoDNS</span> es una herramienta de diagnóstico <span class="font-bold">DNS</span> y consulta de <span class="font-bold">registros DNS</span> que te permitirá conocer el estado de tu <span class="font-bold">dominio</span> y sus <span class="font-bold">registros DNS</span>.<br><br><span class="font-bold">IntoDNS</span> está especialmente pensado para administradores de sitios web que necesiten conocer la <span class="font-bold">configuración DNS</span> de alguno de sus <span class="font-bold">dominios</span> o para comprobar <span class="font-bold">dominios</span> de terceros',
    'how-works-title': '¿Cómo funciona?',
    features: {
      'dns-scan': {
        title: 'Escaneo DNS',
        description:
                    'Escaneamos los servidores DNS del dominio que quieras y mostramos información relevante sobre los registros'
      },
      'dns-check': {
        title: 'Comprobación DNS',
        description:
                    'Comprobamos que los datos conseguidos en el escaneo DNS sean correctos y fiables'
      },
      'dns-info': {
        title: 'Informe DNS',
        description:
                    'Construimos un informe con los datos obtenidos de los servidores DNS de tu dominio'
      }
    },
    'stats-title': 'Estadísticas de IntoDNS',
    'stats-subtitle':
            'A continuación puedes ver algunas estadísticas de uso de IntoDNS',
    'api-title': 'IntoDNS API',
    'api-description': 'Si quieres utilizar en tus proyectos la tecnología de IntoDNS puedes integrar tus programas y servicios con nuestra API, tendrás acceso a todas las funciones de IntoDNS para automatizar el análisis y el testeo DNS.',
    'api-pre-go-to': 'Nuestra API es de acceso público y gratuito. Si quieres saber más accede a:',
    'go-to-docs-api-version': 'Documentación de la API V{version}'
  },
  analysis: {
    'copy-section-link': 'Copiar enlace a la sección',
    'link-copied': 'Enlace copiado',
    'aria-label-copy-to-dns-hosts-section': 'Copiar enlace a la sección "Traza de hosts DNS"',
    'aria-label-copy-to-general-tests-section': 'Copiar enlace a la sección "Tests generales"',
    'aria-label-copy-to-ns-records-section': 'Copiar enlace a la sección "Registros NS"',
    'aria-label-copy-to-soa-record-section': 'Copiar enlace a la sección "Registro SOA"',
    'aria-label-copy-to-target-records-section': 'Copiar enlace a la sección "Registros A / AAAA / CNAME"',
    'aria-label-copy-to-mx-records-section': 'Copiar enlace a la sección "Registros MX"',
    'aria-label-copy-to-txt-records-section': 'Copiar enlace a la sección "Registros TXT"',
    'aria-label-copy-to-spf-record-section': 'Copiar enlace a la sección "Registro SPF"',
    'aria-label-copy-to-dmarc-record-section': 'Copiar enlace a la sección "Registro DMARC"',
    'aria-label-copy-to-caa-records-section': 'Copiar enlace a la sección "Registros CAA"',
    'head-title': 'Análisis e informe para el dominio {domain}',
    'meta-description': 'Análisis e informe sobre los registros y la configuración DNS del dominio seleccionado',
    'view-datails': 'Ver detalles',
    'hide-datails': 'Ocultar detalles',
    yes: 'Si',
    no: 'No',
    empty: 'Vacío',
    'show-test-flags': 'Ver test flags',
    'hide-test-flags': 'Ocultar test flags',
    'visible-details': 'Detalles visibles',
    'hidden-details': 'Detalles ocultos',
    tests: 'Tests',
    'ns-records': 'Registros NS',
    'soa-record': 'Registro SOA',
    'mx-records': 'Registros MX',
    'spf-record': 'Registro SPF',
    'dmarc-record': 'Registro DMARC',
    'target-records': 'Registros A / AAAA / CNAME',
    'txt-records': 'Registros TXT',
    'caa-records': 'Registros CAA',
    'results-of': 'Resultados de {name}',
    'results-table': 'Tabla de resultados',
    'trace-hosts': 'Traza de hosts DNS',
    'go-to-section': 'Ir a la sección \'{section}\'',
    records: 'Registros',
    'record-status': 'Estado',
    'record-details': 'Detalles',
    'analysis-result': 'Resultado del análisis',
    'record-test-flags': 'Tests flags',
    'record-is-correctly-in-all-tests':
            'No se han detectado errores con este registro',
    'parent-requested-for-analysis':
            'Servidor DNS autoritativo seleccionado para las consultas DNS del análisis',
    'irresolvable-host': 'Host irresoluble',
    'no-authoritative-hosts-found':
            'No se han encontrado servidores DNS autoritativos con información sobre el dominio. Cuando esto sucede, puede que estés intentando consultar un dominio que no existe, un subdominio no delegado o un subdominio inexistente',
    'test-successful': 'Tests completados',
    'not-resolved': 'No resuelto',
    category: 'Categoría',
    status: 'Estado',
    test: 'Test',
    information: 'Información',
    retry: 'Reintentar',
    'unexpected-error-in-test':
            'El test no se ha podido completar debido a un error inesperado',
    'records-has-recommended-ttl': 'Los registros tienen un TTL recomendado',
    'records-has-recommended-ttl-test-not-applicable-message': 'Este test no es aplicable al no haber registros que evaluar',
    'any-record-not-recovered': 'No se ha conseguido recuperar ningún registro',
    'zero-records-have-been-recovered': 'Se han recuperado 0 registros',
    'error-in-records-fetch': 'Error al intentar recuperar los registros',
    ip: 'IP',
    ips: 'IPs',
    ipv4: 'IPv4',
    ipv6: 'IPv6',
    host: 'Host',
    target: 'Target',
    priority: 'Prioridad',
    type: 'Tipo',
    tag: 'Tag',
    flags: 'Flags',
    value: 'Valor',
    ptr: 'PTR',
    ttl: 'TTL',
    terms: 'Términos',
    'deploy-correct-tests': 'Desplegar tests correctos',
    'hide-correct-tests': 'Ocultar tests correctos',
    'there-are-n-correct-tests-hidden':
            'Hay <span class="font-bold">{nCorrectTests}</span> tests correctos que se han ocultado',
    'general-tests': 'Tests generales',
    'not-has-any-authority-host-correctly-configured-for-continue-analysis':
            'No se ha encontrado ningún servidor DNS autoritativo correctamente configurado. Esto puede ser debido a que ningún servidor DNS autoritativo de los encontrados en la traza responde a peticiones DNS o que no tienen información sobre el dominio',
    'unresolvable-host': 'Este host no resuelve peticiones DNS',
    'not-has-soa-record': 'Este servidor DNS no tiene registro SOA del dominio',
    'not-has-ns-record': 'Este servidor DNS no tiene registros NS del dominio',
    general: {
      'less-info': 'Menos información',
      'more-info': 'Más información',
      'the-zone-should-not-be-transferable':
                'La zona no debe ser transferible en ningún DNS autoritativo (AXFR)',
      'the-authority-hosts-are-quivalent-to-ns-records':
                'Los DNS autoritativos son equivalentes a los registros NS',
      'all-authority-hosts-has-same-soa':
                'Todos los servidores DNS autoritativos deben tener el mismo SOA',
      'recommended-number-of-authority-hosts': 'Número de DNS autoritativos recomendado',
      'dnssec-protection': 'Protección DNSSEC'
    },
    commons: {
      'records-target-has-resolvable-host-ips':
                'Los registros apuntan a hosts con IPs resolubles'
    },
    NS: {
      'domain-ns-records': 'Registros NS del dominio',
      'ns-not-present-in-the-trace-have-the-same-soa-as-authority-hosts':
                'Servidores NS no encontrados en los DNS autoritativos tiene el mismo registro SOA',
      'ns-not-present-in-the-trace-is-not-transferible':
                'Servidores NS no encontrados en los DNS autoritativos no deben ser transferibles (AXFR)'
    },
    SOA: {
      'not-found': 'Registro SOA no encontrado',
      'mname-term-is-real-authority-host': "El término 'mname' es un servidores DNS autoritativo",
      'serial-term-is-correctly-configured': "Término 'serial' correctamente configurado",
      'refresh-term-is-correctly-configured':
                "Término 'refresh' correctamente configurado",
      'retry-term-is-correctly-configured': "Término 'retry' correctamente configurado",
      'expiry-term-is-correctly-configured': "Término 'expiry' correctamente configurado",
      'minimum-term-is-correctly-configured':
                "Término 'minimum' correctamente configurado",
      info: {
        mname:
                    'Servidor DNS principal del cluster DNS y primer origen de la información de la zona DNS',
        rname: 'Correo de contacto del responsable de la zona DNS',
        serial:
                    'Número que identifica una versión concreta de la zona DNS. Una transferencia de zona mantienen este valor',
        refresh:
                    'Tiempo que los servidores DNS secundarios deben esperar antes de pedir cambios al servidor DNS principal',
        retry:
                    'Tiempo que los servidores DNS deben esperar para volver a pedir una actualización de zona tras haber fallado anteriormente',
        expiry:
                    'Tiempo tras el que los servidores secundarios que no han podido actualizar su zona, dejan de responder consultas DNS',
        minimum: 'Tiempo de vida (TTL) de respuestas de caché negativas'
      }
    },
    'target-hosts': {
      'domain-a-aaaa-cname-records': 'Registros A, AAAA y CNAME del dominio'
    },
    MX: {
      'domain-mx-records': 'Registros MX del dominio',
      'no-records-points-to-cname': 'Ningún registro apunta a un registro CNAME',
      'there-must-not-be-duplicated-records': 'No debe haber registros duplicados'
    },
    TXT: {
      'domain-txt-records': 'Registros TXT del dominio'
    },
    SPF: {
      includes: 'Includes',
      'redirect-to': 'Redirección',
      'authorise-records-a': 'Autoriza los registros A del dominio',
      'authorise-records-mx': 'Autoriza los registros MX del dominio',
      'spf-recovered-but-error-in-process':
                'Se ha podido recuperar el registro SPF pero no se ha conseguido procesar correctamente',
      'authorise-ptr': 'Autoriza el PTR de la IP del dominio',
      EXISTS: 'EXISTS',
      A: 'A',
      MX: 'MX',
      PTR: 'PTR',
      EXP: 'EXP',
      ALL: 'ALL',
      'not-found': 'El registro SPF no se ha encontrado entre los registros TXT',
      'only-one-record-and-correct-format':
                'Solo debe existir un registro con el formato adecuado',
      'a-records-can-send-mails':
                'Los hosts de los registros A y AAAA están autorizados para enviar emails con el nombre del dominio',
      'mx-records-can-send-mails':
                'Los hosts de los registros MX están autorizados para enviar emails con el nombre del dominio',
      'all-term-is-correctly-configured': "Término 'all' correctamente configurado",
      'ptr-mechanism-use-not-recommended': 'Mecanismo PTR desaconsejado',
      info: {
        'authorise-own-a-records':
                    'Autoriza los hosts correspondientes de los registros A / AAAA del valor comprobado',
        'authorise-own-mx-records':
                    'Autoriza los hosts correspondientes de los registros MX del valor comprobado',
        'authorise-own-ptr':
                    'Autoriza el host correspondiente al registro PTR de la IP del valor comprobado',
        a: 'Autoriza los hosts correspondientes al registro A/AAAA',
        mx: 'Autoriza los hosts correspondientes al registro MX',
        ptr: 'Autoriza el host correspondiente al registro PTR',
        ip: 'Autoriza la IPv4 o IPv6 indicada en el propio término',
        include: 'Verifica el host contra registros SPF adicionales',
        redirect:
                    'Cuando ningún término previo valida, redirige la comprobación al host indicado. El dominio de consulta se vuelve el dominio de la redirección cuando se aplica',
        exp:
                    "Establece el registro TXT que contiene la respuesta cuando el resultado es 'fail'",
        exists:
                    'Permite hacer comprobaciones arbitrarias por medio de una expresión que valida el formato de los hosts que se quieren evaluar',
        all:
                    "Acción por defecto cuando otro término no valida. Cuando existe, 'redirect' se ignora"
      }
    },
    DMARC: {
      'not-found':
                'El registro DMARC no se ha encontrado entre los registros TXT del subdominio _dmarc.{domain}',
      'requested-domain':
                'Se ha consultado el subdominio <span class="text-white">_dmarc.{domain}</span>',
      'only-one-record-and-correct-format':
                'Solo debe existir un registro con el formato adecuado',
      'policy-term-correctly-configured':
                "Término 'p' correctamente configurado",
      'subdomain-policy-term-correctly-configured':
                "Término 'sp' correctamente configurado",
      'pct-term-correctly-configured':
                "Término 'pct' correctamente configurado",
      'rua-and-ruf-term-correctly-configured':
                "Términos 'rua' y 'ruf' correctamente configurados",
      'fo-term-correctly-configured':
                "Término 'fo' correctamente configurado",
      'adkim-term-correctly-configured':
                "Término 'adkim' correctamente configurado",
      'aspf-term-correctly-configured':
                "Término 'aspf' correctamente configurado",
      info: {
        p: 'Especifica la política a seguir cuando la validación falla',
        rua:
                    'URI o URIs a la que enviar reportes agregados de la actividad de validación',
        ruf:
                    'URI o URIs a la que enviar reportes forenses de fallos de validación específicos',
        adkim:
                    'Especifica el nivel de alineamiento requerido entre el DKIM y el dominio del From',
        aspf:
                    'Especifica el nivel de alineamiento requerido entre el SPF y el dominio del From',
        fo:
                    'Indica el tipo de fallo de validación necesario para generar un reporte forense',
        pct: "Porcentaje de aplicación de políticas 'quarantine' o 'reject'",
        rf: 'Especifica el formato utilizado para los reportes forenses enviados',
        ri:
                    'Intervalo de tiempo (máximo) entre reportes agregados, requerido a servidores destinatarios',
        sp:
                    "Equivalente a 'p', pero aplicable únicamente a subdominios. Si no existe, se aplica 'p'"
      }
    },
    CAA: {
      'accepted-certifying-bodies': 'Entidades certificadoras aceptadas',
      'accept-all-certifying-bodies-message': 'Al no encontrar registros de restricción (con tag \'issue\' o \'issuewild\') se acepta cualquier entidad certificadora',
      'deny-all-certifying-bodies-message': 'Al tener solo un registro de restricción vacío (con tag \'issue\' o \'issuewild\') se restringe cualquier entidad certificadora'
    },
    accesibility: {
      'alt-email-icon': 'Icono de email',
      'aria-label-copy-btn': 'Copiar contenido del registro'
    },
    'what-is': "¿Qué es '{key}'?"
  },
  'feedback-form': {
    title: 'Sugerencias y reporte de errores',
    description: 'Puedes enviarnos sugerencias o avisarnos de cualquier error que hayas encontrado',
    'email-contact': 'Correo de contacto',
    'email-place-holder': "example{'@'}example.com",
    'email-aria-label': 'Correo de contacto del formulario de sugerencias y reporte de errores',
    'issue-description': 'Descripción de la sugerencia o el error encontrado',
    'issue-description-place-holder': 'Escribe aquí tu mensaje',
    'description-aria-label': 'Descripción de la sugerencia o el error encontrado del formulario de sugerencias y reporte de errores',
    send: 'Enviar',
    'head-title': 'Sugerencias y reporte de errores',
    'meta-description': 'Formulario para enviar sugerencias y reporte de errores',
    'send-btn-aria-label': 'Botón de envío del formulario de sugerencias y reporte de errores',
    'go-to-feedback-btn-aria-label': 'Ir al formulario de Sugerencias y reporte de errores'
  }
}
