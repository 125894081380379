import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faBinoculars,
  faGears,
  faInfo,
  faGlobe,
  faLaptop,
  faEarthEurope,
  faArrowDown,
  faCircleCheck,
  faXmark,
  faCircleInfo,
  faCircleXmark,
  faTriangleExclamation,
  faCopy,
  faExclamationCircle,
  faRepeat,
  faBolt,
  faQuestion,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faSquarePlus,
  faSquareMinus,
  faFlagCheckered,
  faMagnifyingGlass,
  faMessage,
  faCode,
  faLink,
  faServer
} from '@fortawesome/free-solid-svg-icons'
import { faFolder, faEnvelope, faClock, faPaperPlane, faMessage as farMessage } from '@fortawesome/free-regular-svg-icons'

export function bootFontAwesome (): void {
  // Config Font-Awesome
  library.add(
    faBinoculars,
    faGears,
    faInfo,
    faCircleInfo,
    faGlobe,
    faFolder,
    faLaptop,
    faEnvelope,
    faEarthEurope,
    faArrowDown,
    faCircleCheck,
    faXmark,
    faCircleXmark,
    faTriangleExclamation,
    faCopy,
    faExclamationCircle,
    faRepeat,
    faClock,
    faBolt,
    faQuestion,
    faChevronUp,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faSquarePlus,
    faSquareMinus,
    faFlagCheckered,
    faMagnifyingGlass,
    faMessage,
    faPaperPlane,
    farMessage,
    faCode,
    faLink,
    faServer
  )
}
