export default {
  'unexpected-error': 'Erreur inattendue',
  'copied-to-clipboard': 'Copié dans le presse-papier',
  header: {
    title: 'Vérifiez et analysez les enregistrements DNS de votre domaine',
    'logo-alt': 'IntoDNS',
    'search-button-aria-label': 'Analyser le domaine',
    analize: 'Analyser',
    loading: 'Chargement',
    'search-bar-aria-label': 'Barre de recherche de domaine',
    'change-language': 'Changer de langue',
    'select-language': 'Sélectionner la langue \'{lang}\'',
    'selected-language-flag': 'Drapeau de la langue sélectionnée de \'{lang}\'',
    'alternative-language-flag': 'Drapeau de langue alternative de \'{lang}\''
  },
  footer: {
    title: 'Besoin d\'hébergement pour votre domaine?',
    description:
            'Chez Raiola Networks, nous proposons un hébergement pour votre domaine. En termes de serveurs DNS, nous sommes bien couverts, car nous avons des serveurs DNS redondants et distribués dans le monde entier pour les services hébergés chez nous',
    'discover-it': 'Découvrez-le!',
    'visit-our-blog': 'Visitez notre blog!',
    'raiola-server-alt': 'Serveur Raiola',
    'raiola-team-alt': 'Équipe des systèmes de Raiola Networks',
    'api-docs': 'Documentation de l\'API',
    'go-to-docs-api-version': 'Aller à V{version}'
  },
  landing: {
    domains: 'Domaines',
    'dns-records': 'Enregistrements DNS',
    'a-records': 'Enregistrements A',
    'ns-records': 'Enregistrements NS',
    'mx-records': 'Enregistrements MX',
    'head-title': 'Vérifiez et analysez les enregistrements DNS de votre domaine',
    'meta-description': 'Utilisez IntoDNS pour analyser, vérifier et générer des rapports sur les enregistrements DNS d\'un domaine. C\'est un outil d\'analyse idéal pour les administrateurs web',
    'main-title': 'Qu\'est-ce qu\'IntoDNS?',
    'main-description':
            '<span class="font-bold">IntoDNS</span> est un outil de diagnostic <span class="font-bold">DNS</span> et de consultation des <span class="font-bold">enregistrements DNS</span> qui vous permet de connaître l\'état de votre <span class="font-bold">domaine</span> et de ses <span class="font-bold">enregistrements DNS</span>.<br><br><span class="font-bold">IntoDNS</span> est spécialement conçu pour les administrateurs de sites web qui ont besoin de connaître la <span class="font-bold">configuration DNS</span> de l\'un de leurs <span class="font-bold">domaines</span> ou pour vérifier les <span class="font-bold">domaines</span> de tiers',
    'how-works-title': 'Comment ça fonctionne?',
    features: {
      'dns-scan': {
        title: 'Analyse DNS',
        description:
                    'Nous analysons les serveurs DNS du domaine que vous souhaitez et affichons des informations pertinentes sur les enregistrements'
      },
      'dns-check': {
        title: 'Vérification DNS',
        description:
                    'Nous vérifions que les données obtenues lors de l\'analyse DNS sont correctes et fiables'
      },
      'dns-info': {
        title: 'Rapport DNS',
        description:
                    'Nous élaborons un rapport avec les données obtenues des serveurs DNS de votre domaine'
      }
    },
    'stats-title': 'Statistiques d\'IntoDNS',
    'stats-subtitle':
            'Vous pouvez voir ci-dessous quelques statistiques d\'utilisation d\'IntoDNS',
    'api-title': 'API IntoDNS',
    'api-description': 'Si vous souhaitez utiliser la technologie IntoDNS dans vos projets, vous pouvez intégrer vos programmes et services avec notre API. Vous aurez accès à toutes les fonctions d\'IntoDNS pour automatiser l\'analyse et les tests DNS.',
    'api-pre-go-to': 'Notre API est publique et accessible gratuitement. Si vous voulez en savoir plus, allez à:',
    'go-to-docs-api-version': 'Documentation de l\'API V{version}'

  },
  analysis: {
    'copy-section-link': 'Copier le lien de la section',
    'link-copied': 'Lien copié',
    'aria-label-copy-to-dns-hosts-section': 'Copier le lien vers la section "Trace des hôtes DNS"',
    'aria-label-copy-to-general-tests-section': 'Copier le lien vers la section "Tests généraux"',
    'aria-label-copy-to-ns-records-section': 'Copier le lien vers la section "Enregistrements NS"',
    'aria-label-copy-to-soa-record-section': 'Copier le lien vers la section "Enregistrement SOA"',
    'aria-label-copy-to-target-records-section': 'Copier le lien vers la section "Enregistrements A / AAAA / CNAME"',
    'aria-label-copy-to-mx-records-section': 'Copier le lien vers la section "Enregistrements MX"',
    'aria-label-copy-to-txt-records-section': 'Copier le lien vers la section "Enregistrements TXT"',
    'aria-label-copy-to-spf-record-section': 'Copier le lien vers la section "Enregistrement SPF"',
    'aria-label-copy-to-dmarc-record-section': 'Copier le lien vers la section "Enregistrement DMARC"',
    'aria-label-copy-to-caa-records-section': 'Copier le lien vers la section "Enregistrements CAA"',
    'head-title': 'Analyse et rapport pour le domaine {domain}',
    'meta-description': 'Analyse et rapport sur les enregistrements DNS et la configuration du domaine sélectionné',
    'view-datails': 'Voir les détails',
    'hide-datails': 'Masquer les détails',
    yes: 'Oui',
    no: 'Non',
    empty: 'Vide',
    'show-test-flags': 'Voir les drapeaux de test',
    'hide-test-flags': 'Masquer les drapeaux de test',
    'visible-details': 'Détails visibles',
    'hidden-details': 'Détails masqués',
    tests: 'Tests',
    'ns-records': 'Enregistrements NS',
    'soa-record': 'Enregistrement SOA',
    'mx-records': 'Enregistrements MX',
    'spf-record': 'Enregistrement SPF',
    'dmarc-record': 'Enregistrement DMARC',
    'target-records': 'Enregistrements A / AAAA / CNAME',
    'txt-records': 'Enregistrements TXT',
    'caa-records': 'Enregistrements CAA',
    'results-of': 'Résultats de {name}',
    'results-table': 'Tableau des résultats',
    'trace-hosts': 'Tracer les hôtes DNS',
    'go-to-section': 'Aller à la section \'{section}\'',
    records: 'Enregistrements',
    'record-status': 'Statut',
    'record-details': 'Détails',
    'analysis-result': 'Résultat de l\'analyse',
    'record-test-flags': 'Drapeaux de test',
    'record-is-correctly-in-all-tests':
            'Aucune erreur détectée avec cet enregistrement',
    'parent-requested-for-analysis':
            'Serveur DNS autoritatif sélectionné pour les requêtes DNS de l\'analyse',
    'irresolvable-host': 'Hôte non résolvable',
    'no-authoritative-hosts-found':
            'Aucun serveur DNS autoritaire contenant des informations sur le domaine n\'a été trouvé. Lorsque cela se produit, il se peut que vous essayiez de consulter un domaine inexistant, un sous-domaine non délégué ou un sous-domaine inexistant',
    'test-successful': 'Tests terminés',
    'not-resolved': 'Non résolu',
    category: 'Catégorie',
    status: 'Statut',
    test: 'Test',
    information: 'Information',
    retry: 'Réessayer',
    'unexpected-error-in-test':
            'Le test n\'a pas pu être complété en raison d\'une erreur inattendue',
    'records-has-recommended-ttl': 'Les enregistrements ont un TTL recommandé',
    'records-has-recommended-ttl-test-not-applicable-message': 'Ce test n\'est pas applicable car il n\'y a pas d\'enregistrements à évaluer',
    'any-record-not-recovered': 'Aucun enregistrement n\'a pu être récupéré',
    'zero-records-have-been-recovered': '0 enregistrements ont été récupérés',
    'error-in-records-fetch': 'Erreur lors de la récupération des enregistrements',
    ip: 'IP',
    ips: 'IPs',
    ipv4: 'IPv4',
    ipv6: 'IPv6',
    host: 'Hôte',
    target: 'Cible',
    priority: 'Priorité',
    type: 'Type',
    tag: 'Tag',
    flags: 'Drapeaux',
    value: 'Valeur',
    ptr: 'PTR',
    ttl: 'TTL',
    terms: 'Termes',
    'deploy-correct-tests': 'Déployer les tests corrects',
    'hide-correct-tests': 'Masquer les tests corrects',
    'there-are-n-correct-tests-hidden':
            'Il y a <span class="font-bold">{nCorrectTests}</span> tests corrects qui ont été masqués',
    'general-tests': 'Tests généraux',
    'not-has-any-authority-host-correctly-configured-for-continue-analysis':
            'Aucun serveur DNS autoritatif correctement configuré n\'a été trouvé. Cela peut être dû au fait qu\'aucun serveur DNS autoritatif trouvé dans la trace ne répond aux requêtes DNS ou qu\'ils n\'ont pas d\'informations sur le domaine',
    'unresolvable-host': 'Cet hôte ne résout pas les requêtes DNS',
    'not-has-soa-record': 'Ce serveur DNS n\'a pas d\'enregistrement SOA pour le domaine',
    'not-has-ns-record': 'Ce serveur DNS n\'a pas d\'enregistrements NS pour le domaine',
    general: {
      'less-info': 'Moins d\'informations',
      'more-info': 'Plus d\'informations',
      'the-zone-should-not-be-transferable':
                'La zone ne doit pas être transférable dans aucun DNS autoritatif (AXFR)',
      'the-authority-hosts-are-quivalent-to-ns-records':
                'Les DNS autoritatifs sont équivalents aux enregistrements NS',
      'all-authority-hosts-has-same-soa':
                'Tous les serveurs DNS autoritatifs doivent avoir le même SOA',
      'recommended-number-of-authority-hosts': 'Nombre recommandé de serveurs DNS autoritatifs',
      'dnssec-protection': 'Protection DNSSEC'
    },
    commons: {
      'records-target-has-resolvable-host-ips':
                'Les enregistrements pointent vers des hôtes avec des IPs résolvables'
    },
    NS: {
      'domain-ns-records': 'Enregistrements NS du domaine',
      'ns-not-present-in-the-trace-have-the-same-soa-as-authority-hosts':
                'Les serveurs NS non trouvés dans les DNS autoritatifs ont le même enregistrement SOA',
      'ns-not-present-in-the-trace-is-not-transferible':
                'Les serveurs NS non trouvés dans les DNS autoritatifs ne doivent pas être transférables (AXFR)'
    },
    SOA: {
      'not-found': 'Enregistrement SOA non trouvé',
      'mname-term-is-real-authority-host': "Le terme 'mname' est l'un des serveurs DNS autoritatifs",
      'serial-term-is-correctly-configured': "Terme 'serial' correctement configuré",
      'refresh-term-is-correctly-configured':
                "Terme 'refresh' correctement configuré",
      'retry-term-is-correctly-configured': "Terme 'retry' correctement configuré",
      'expiry-term-is-correctly-configured': "Terme 'expiry' correctement configuré",
      'minimum-term-is-correctly-configured':
                "Terme 'minimum' correctement configuré",
      info: {
        mname:
                    'Serveur DNS principal du cluster DNS et source principale des informations de la zone DNS',
        rname: 'Email de contact de l\'administrateur de la zone DNS',
        serial:
                    'Numéro identifiant une version spécifique de la zone DNS. Un transfert de zone conserve cette valeur',
        refresh:
                    'Temps que les serveurs DNS secondaires doivent attendre avant de demander des modifications au serveur DNS principal',
        retry:
                    'Temps que les serveurs DNS doivent attendre avant de redemander une mise à jour de la zone après un échec',
        expiry:
                    'Temps après lequel les serveurs secondaires qui n\'ont pas pu mettre à jour leur zone cessent de répondre aux requêtes DNS',
        minimum: 'Durée de vie (TTL) des réponses de cache négatives'
      }
    },
    'target-hosts': {
      'domain-a-aaaa-cname-records': 'Enregistrements A, AAAA et CNAME du domaine'
    },
    MX: {
      'domain-mx-records': 'Enregistrements MX du domaine',
      'no-records-points-to-cname': 'Aucun enregistrement ne pointe vers un enregistrement CNAME',
      'there-must-not-be-duplicated-records': 'Il ne doit pas y avoir d\'enregistrements dupliqués'
    },
    TXT: {
      'domain-txt-records': 'Enregistrements TXT du domaine'
    },
    SPF: {
      includes: 'Inclut',
      'redirect-to': 'Rediriger vers',
      'authorise-records-a': 'Autorise les enregistrements A du domaine',
      'authorise-records-mx': 'Autorise les enregistrements MX du domaine',
      'spf-recovered-but-error-in-process':
                'L\'enregistrement SPF a pu être récupéré mais n\'a pas pu être traité correctement',
      'authorise-ptr': 'Autorise le PTR de l\'IP du domaine',
      EXISTS: 'EXISTS',
      A: 'A',
      MX: 'MX',
      PTR: 'PTR',
      EXP: 'EXP',
      ALL: 'ALL',
      'not-found': 'L\'enregistrement SPF n\'a pas été trouvé parmi les enregistrements TXT',
      'only-one-record-and-correct-format':
                'Il ne doit y avoir qu\'un seul enregistrement avec le format correct',
      'a-records-can-send-mails':
                'Les hôtes des enregistrements A et AAAA sont autorisés à envoyer des emails avec le nom de domaine',
      'mx-records-can-send-mails':
                'Les hôtes des enregistrements MX sont autorisés à envoyer des emails avec le nom de domaine',
      'all-term-is-correctly-configured': "Terme 'all' correctement configuré",
      'ptr-mechanism-use-not-recommended': 'L\'utilisation du mécanisme PTR n\'est pas recommandée',
      info: {
        'authorise-own-a-records':
                    'Autorise les hôtes correspondants des enregistrements A / AAAA de la valeur vérifiée',
        'authorise-own-mx-records':
                    'Autorise les hôtes correspondants des enregistrements MX de la valeur vérifiée',
        'authorise-own-ptr':
                    'Autorise l\'hôte correspondant de l\'enregistrement PTR de l\'IP de la valeur vérifiée',
        a: 'Autorise les hôtes correspondants de l\'enregistrement A/AAAA',
        mx: 'Autorise les hôtes correspondants de l\'enregistrement MX',
        ptr: 'Autorise l\'hôte correspondant de l\'enregistrement PTR',
        ip: 'Autorise l\'IPv4 ou l\'IPv6 indiquée dans le terme lui-même',
        include: 'Vérifie l\'hôte par rapport à des enregistrements SPF supplémentaires',
        redirect:
                    'Lorsque aucun terme précédent ne valide, redirige la vérification vers l\'hôte indiqué. Le domaine de requête devient le domaine de redirection lorsqu\'il est appliqué',
        exp:
                    "Définit l'enregistrement TXT contenant la réponse lorsque le résultat est 'fail'",
        exists:
                    'Permet de faire des vérifications arbitraires via une expression qui valide le format des hôtes à évaluer',
        all:
                    "Action par défaut lorsque aucun autre terme ne valide. Lorsqu'il existe, 'redirect' est ignoré"
      }
    },
    DMARC: {
      'not-found':
                'L\'enregistrement DMARC n\'a pas été trouvé parmi les enregistrements TXT du sous-domaine _dmarc.{domain}',
      'requested-domain':
                'Le sous-domaine <span class="text-white">_dmarc.{domain}</span> a été interrogé',
      'only-one-record-and-correct-format':
                'Il ne doit y avoir qu\'un seul enregistrement avec le format correct',
      'policy-term-correctly-configured':
                'Terme de politique correctement configuré',
      'subdomain-policy-term-correctly-configured':
                'Terme de politique de sous-domaine correctement configuré',
      'pct-term-correctly-configured':
                'Terme PCT correctement configuré',
      'rua-and-ruf-term-correctly-configured':
                'Termes RUA et RUF correctement configurés',
      'fo-term-correctly-configured':
                'Terme FO correctement configuré',
      'adkim-term-correctly-configured':
                'Terme ADKIM correctement configuré',
      'aspf-term-correctly-configured':
                'Terme ASPF correctement configuré',
      info: {
        p: 'Spécifie la politique à suivre lorsque la validation échoue',
        rua:
                    'URI ou URIs où envoyer des rapports agrégés de l\'activité de validation',
        ruf:
                    'URI ou URIs où envoyer des rapports médico-légaux sur des échecs de validation spécifiques',
        adkim:
                    'Spécifie le niveau d\'alignement requis entre le DKIM et le domaine From',
        aspf:
                    'Spécifie le niveau d\'alignement requis entre le SPF et le domaine From',
        fo:
                    'Indique le type d\'échec de validation nécessaire pour générer un rapport médico-légal',
        pct: "Pourcentage d'application des politiques 'quarantine' ou 'reject'",
        rf: 'Spécifie le format utilisé pour les rapports médico-légaux envoyés',
        ri:
                    'Intervalle de temps (maximum) entre les rapports agrégés, requis par les serveurs destinataires',
        sp:
                    "Équivalent à 'p', mais applicable uniquement aux sous-domaines. S'il n'existe pas, 'p' s'applique"
      }
    },
    CAA: {
      'accepted-certifying-bodies': 'Organismes de certification acceptés',
      'accept-all-certifying-bodies-message': 'En l\'absence d\'enregistrements de restriction (avec le tag \'issue\' ou \'issuewild\'), tout organisme de certification est accepté',
      'deny-all-certifying-bodies-message': 'Avec un seul enregistrement de restriction vide (avec le tag \'issue\' ou \'issuewild\'), tout organisme de certification est restreint'
    },
    accesibility: {
      'alt-email-icon': 'Icône de courrier électronique',
      'aria-label-copy-btn': 'Copier le contenu de l\'enregistrement'
    },
    'what-is': "Qu'est-ce que '{key}'?"
  },
  'feedback-form': {
    title: 'Suggestions et signalement d\'erreurs',
    description: 'Vous pouvez nous envoyer des suggestions ou nous signaler toute erreur que vous avez trouvée',
    'email-contact': 'Email de contact',
    'email-place-holder': "exemple{'@'}exemple.com",
    'email-aria-label': 'Email de contact pour le formulaire de suggestions et de signalement d\'erreurs',
    'issue-description': 'Description de la suggestion ou de l\'erreur trouvée',
    'issue-description-place-holder': 'Écrivez votre message ici',
    'description-aria-label': 'Description de la suggestion ou de l\'erreur trouvée pour le formulaire de suggestions et de signalement d\'erreurs',
    send: 'Envoyer',
    'head-title': 'Suggestions et signalement d\'erreurs',
    'meta-description': 'Formulaire pour envoyer des suggestions et signaler des erreurs',
    'send-btn-aria-label': 'Bouton d\'envoi pour le formulaire de suggestions et de signalement d\'erreurs',
    'go-to-feedback-btn-aria-label': 'Aller au formulaire de Suggestions et signalement d\'erreurs'
  }
}
