export default {
  'unexpected-error': 'Erro inesperado',
  'copied-to-clipboard': 'Copiado para a área de transferência',
  header: {
    title: 'Verifique e analise os registos DNS do seu domínio',
    'logo-alt': 'IntoDNS',
    'search-button-aria-label': 'Analisar o domínio',
    analize: 'Analisar',
    loading: 'A carregar',
    'search-bar-aria-label': 'Barra de pesquisa de domínios',
    'change-language': 'Mudar idioma',
    'select-language': 'Selecionar idioma \'{lang}\'',
    'selected-language-flag': 'Bandeira do idioma selecionado de \'{lang}\'',
    'alternative-language-flag': 'Bandeira do idioma alternativo de \'{lang}\''
  },
  footer: {
    title: 'Precisa de alojamento para o seu domínio?',
    description:
            'Na Raiola Networks oferecemos alojamento para o seu domínio. Em termos de servidores DNS, estamos bem cobertos, pois temos servidores DNS redundantes e distribuídos em todo o mundo para os serviços alojados connosco',
    'discover-it': 'Descubra!',
    'visit-our-blog': 'Visite o nosso blog!',
    'raiola-server-alt': 'Servidor Raiola',
    'raiola-team-alt': 'Equipa de Sistemas da Raiola Networks',
    'api-docs': 'Documentação da API',
    'go-to-docs-api-version': 'Ir para V{version}'
  },
  landing: {
    domains: 'Domínios',
    'dns-records': 'Registos DNS',
    'a-records': 'Registos A',
    'ns-records': 'Registos NS',
    'mx-records': 'Registos MX',
    'head-title': 'Verifique e analise os registos DNS do seu domínio',
    'meta-description': 'Use o IntoDNS para verificar, analisar e gerar relatórios de registos DNS sobre um domínio. É uma ferramenta de análise ideal para administradores web',
    'main-title': 'O que é o IntoDNS?',
    'main-description':
            '<span class="font-bold">IntoDNS</span> é uma ferramenta de diagnóstico <span class="font-bold">DNS</span> e consulta de <span class="font-bold">registos DNS</span> que permite conhecer o estado do seu <span class="font-bold">domínio</span> e dos seus <span class="font-bold">registos DNS</span>.<br><br><span class="font-bold">IntoDNS</span> é especialmente concebido para administradores de sites web que precisam de conhecer a <span class="font-bold">configuração DNS</span> de algum dos seus <span class="font-bold">domínios</span> ou para verificar <span class="font-bold">domínios</span> de terceiros',
    'how-works-title': 'Como funciona?',
    features: {
      'dns-scan': {
        title: 'Verificação DNS',
        description:
                    'Verificamos os servidores DNS do domínio que deseja e mostramos informações relevantes sobre os registos'
      },
      'dns-check': {
        title: 'Verificação DNS',
        description:
                    'Verificamos se os dados obtidos na verificação DNS estão corretos e são fiáveis'
      },
      'dns-info': {
        title: 'Relatório DNS',
        description:
                    'Criamos um relatório com os dados obtidos dos servidores DNS do seu domínio'
      }
    },
    'stats-title': 'Estatísticas do IntoDNS',
    'stats-subtitle':
            'Abaixo pode ver algumas estatísticas de uso do IntoDNS',
    'api-title': 'IntoDNS API',
    'api-description': 'Se você quiser usar a tecnologia IntoDNS em seus projetos, pode integrar seus programas e serviços com nossa API. Você terá acesso a todas as funções do IntoDNS para automatizar a análise e o teste de DNS.',
    'api-pre-go-to': 'Nossa API é de acesso público e gratuito. Se você quiser saber mais, acesse:',
    'go-to-docs-api-version': 'Documentação da API V{version}'
  },
  analysis: {
    'copy-section-link': 'Copiar link da seção',
    'link-copied': 'Link copiado',
    'aria-label-copy-to-dns-hosts-section': 'Copiar link para a seção "Rastreamento de hosts DNS"',
    'aria-label-copy-to-general-tests-section': 'Copiar link para a seção "Testes gerais"',
    'aria-label-copy-to-ns-records-section': 'Copiar link para a seção "Registros NS"',
    'aria-label-copy-to-soa-record-section': 'Copiar link para a seção "Registro SOA"',
    'aria-label-copy-to-target-records-section': 'Copiar link para a seção "Registros A / AAAA / CNAME"',
    'aria-label-copy-to-mx-records-section': 'Copiar link para a seção "Registros MX"',
    'aria-label-copy-to-txt-records-section': 'Copiar link para a seção "Registros TXT"',
    'aria-label-copy-to-spf-record-section': 'Copiar link para a seção "Registro SPF"',
    'aria-label-copy-to-dmarc-record-section': 'Copiar link para a seção "Registro DMARC"',
    'aria-label-copy-to-caa-records-section': 'Copiar link para a seção "Registros CAA"',
    'head-title': 'Análise e relatório para o domínio {domain}',
    'meta-description': 'Análise e relatório sobre os registos e a configuração DNS do domínio selecionado',
    'view-datails': 'Ver detalhes',
    'hide-datails': 'Ocultar detalhes',
    yes: 'Sim',
    no: 'Não',
    empty: 'Vazio',
    'show-test-flags': 'Ver flags de teste',
    'hide-test-flags': 'Ocultar flags de teste',
    'visible-details': 'Detalhes visíveis',
    'hidden-details': 'Detalhes ocultos',
    tests: 'Testes',
    'ns-records': 'Registos NS',
    'soa-record': 'Registo SOA',
    'mx-records': 'Registos MX',
    'spf-record': 'Registo SPF',
    'dmarc-record': 'Registo DMARC',
    'target-records': 'Registos A / AAAA / CNAME',
    'txt-records': 'Registos TXT',
    'caa-records': 'Registos CAA',
    'results-of': 'Resultados de {name}',
    'results-table': 'Tabela de resultados',
    'trace-hosts': 'Traçar hosts DNS',
    'go-to-section': 'Ir para a seção \'{section}\'',
    records: 'Registos',
    'record-status': 'Estado',
    'record-details': 'Detalhes',
    'analysis-result': 'Resultado da análise',
    'record-test-flags': 'Flags de teste',
    'record-is-correctly-in-all-tests':
            'Não foram detetados erros com este registo',
    'parent-requested-for-analysis':
            'Servidor DNS autoritativo selecionado para as consultas DNS da análise',
    'irresolvable-host': 'Host não resolvível',
    'no-authoritative-hosts-found':
            'Não foram encontrados servidores DNS autoritativos com informações sobre o domínio. Quando isso acontece, pode ser que você esteja tentando consultar um domínio que não existe, um subdomínio não delegado ou um subdomínio inexistente',
    'test-successful': 'Testes concluídos',
    'not-resolved': 'Não resolvido',
    category: 'Categoria',
    status: 'Estado',
    test: 'Teste',
    information: 'Informação',
    retry: 'Tentar novamente',
    'unexpected-error-in-test':
            'O teste não pôde ser concluído devido a um erro inesperado',
    'records-has-recommended-ttl': 'Os registos têm um TTL recomendado',
    'records-has-recommended-ttl-test-not-applicable-message': 'Este teste não é aplicável por não haver registos a avaliar',
    'any-record-not-recovered': 'Nenhum registo pôde ser recuperado',
    'zero-records-have-been-recovered': '0 registos foram recuperados',
    'error-in-records-fetch': 'Erro ao tentar recuperar os registos',
    ip: 'IP',
    ips: 'IPs',
    ipv4: 'IPv4',
    ipv6: 'IPv6',
    host: 'Host',
    target: 'Alvo',
    priority: 'Prioridade',
    type: 'Tipo',
    tag: 'Tag',
    flags: 'Flags',
    value: 'Valor',
    ptr: 'PTR',
    ttl: 'TTL',
    terms: 'Termos',
    'deploy-correct-tests': 'Mostrar testes corretos',
    'hide-correct-tests': 'Ocultar testes corretos',
    'there-are-n-correct-tests-hidden':
            'Existem <span class="font-bold">{nCorrectTests}</span> testes corretos que foram ocultados',
    'general-tests': 'Testes gerais',
    'not-has-any-authority-host-correctly-configured-for-continue-analysis':
            'Não foi encontrado nenhum servidor DNS autoritativo corretamente configurado. Isto pode ser devido a nenhum servidor DNS autoritativo encontrado na trajetória responder a pedidos DNS ou não ter informações sobre o domínio',
    'unresolvable-host': 'Este host não resolve pedidos DNS',
    'not-has-soa-record': 'Este servidor DNS não tem o registo SOA do domínio',
    'not-has-ns-record': 'Este servidor DNS não tem registos NS do domínio',
    general: {
      'less-info': 'Menos informações',
      'more-info': 'Mais informações',
      'the-zone-should-not-be-transferable':
                'A zona não deve ser transferível em nenhum DNS autoritativo (AXFR)',
      'the-authority-hosts-are-quivalent-to-ns-records':
                'Os DNS autoritativos são equivalentes aos registos NS',
      'all-authority-hosts-has-same-soa':
                'Todos os servidores DNS autoritativos devem ter o mesmo SOA',
      'recommended-number-of-authority-hosts': 'Número recomendado de servidores DNS autoritativos',
      'dnssec-protection': 'Proteção DNSSEC'
    },
    commons: {
      'records-target-has-resolvable-host-ips':
                'Os registos apontam para hosts com IPs resolvíveis'
    },
    NS: {
      'domain-ns-records': 'Registos NS do domínio',
      'ns-not-present-in-the-trace-have-the-same-soa-as-authority-hosts':
                'Os servidores NS não encontrados nos DNS autoritativos têm o mesmo registo SOA',
      'ns-not-present-in-the-trace-is-not-transferible':
                'Os servidores NS não encontrados nos DNS autoritativos não devem ser transferíveis (AXFR)'
    },
    SOA: {
      'not-found': 'Registo SOA não encontrado',
      'mname-term-is-real-authority-host': "O termo 'mname' é um dos servidores DNS autoritativos",
      'serial-term-is-correctly-configured': "Termo 'serial' corretamente configurado",
      'refresh-term-is-correctly-configured':
                "Termo 'refresh' corretamente configurado",
      'retry-term-is-correctly-configured': "Termo 'retry' corretamente configurado",
      'expiry-term-is-correctly-configured': "Termo 'expiry' corretamente configurado",
      'minimum-term-is-correctly-configured':
                "Termo 'minimum' corretamente configurado",
      info: {
        mname:
                    'Servidor DNS principal do cluster DNS e fonte principal das informações da zona DNS',
        rname: 'Email de contacto do administrador da zona DNS',
        serial:
                    'Número que identifica uma versão específica da zona DNS. Uma transferência de zona mantém este valor',
        refresh:
                    'Tempo que os servidores DNS secundários devem esperar antes de pedir alterações ao servidor DNS principal',
        retry:
                    'Tempo que os servidores DNS devem esperar antes de voltar a pedir uma atualização da zona após uma falha',
        expiry:
                    'Tempo após o qual os servidores secundários que não conseguiram atualizar a sua zona deixam de responder a pedidos DNS',
        minimum: 'Tempo de vida (TTL) das respostas de cache negativas'
      }
    },
    'target-hosts': {
      'domain-a-aaaa-cname-records': 'Registos A, AAAA e CNAME do domínio'
    },
    MX: {
      'domain-mx-records': 'Registos MX do domínio',
      'no-records-points-to-cname': 'Nenhum registo aponta para um registo CNAME',
      'there-must-not-be-duplicated-records': 'Não deve haver registos duplicados'
    },
    TXT: {
      'domain-txt-records': 'Registos TXT do domínio'
    },
    SPF: {
      includes: 'Inclui',
      'redirect-to': 'Redirecionar para',
      'authorise-records-a': 'Autoriza os registos A do domínio',
      'authorise-records-mx': 'Autoriza os registos MX do domínio',
      'spf-recovered-but-error-in-process':
                'O registo SPF pôde ser recuperado mas não processado corretamente',
      'authorise-ptr': 'Autoriza o PTR do IP do domínio',
      EXISTS: 'EXISTS',
      A: 'A',
      MX: 'MX',
      PTR: 'PTR',
      EXP: 'EXP',
      ALL: 'ALL',
      'not-found': 'O registo SPF não foi encontrado entre os registos TXT',
      'only-one-record-and-correct-format':
                'Deve existir apenas um registo com o formato correto',
      'a-records-can-send-mails':
                'Os hosts dos registos A e AAAA estão autorizados a enviar emails com o nome do domínio',
      'mx-records-can-send-mails':
                'Os hosts dos registos MX estão autorizados a enviar emails com o nome do domínio',
      'all-term-is-correctly-configured': "Termo 'all' corretamente configurado",
      'ptr-mechanism-use-not-recommended': 'Uso do mecanismo PTR não recomendado',
      info: {
        'authorise-own-a-records':
                    'Autoriza os hosts correspondentes dos registos A / AAAA do valor verificado',
        'authorise-own-mx-records':
                    'Autoriza os hosts correspondentes dos registos MX do valor verificado',
        'authorise-own-ptr':
                    'Autoriza o host correspondente ao registo PTR do IP do valor verificado',
        a: 'Autoriza os hosts correspondentes ao registo A / AAAA',
        mx: 'Autoriza os hosts correspondentes ao registo MX',
        ptr: 'Autoriza o host correspondente ao registo PTR',
        ip: 'Autoriza o IPv4 ou IPv6 indicado no próprio termo',
        include: 'Verifica o host em relação a registos SPF adicionais',
        redirect:
                    'Quando nenhum termo anterior valida, redireciona a verificação para o host indicado. O domínio da consulta torna-se o domínio do redirecionamento quando aplicado',
        exp:
                    "Define o registo TXT que contém a resposta quando o resultado é 'fail'",
        exists:
                    'Permite fazer verificações arbitrárias através de uma expressão que valida o formato dos hosts a serem avaliados',
        all:
                    "Ação padrão quando nenhum outro termo valida. Quando existe, 'redirect' é ignorado"
      }
    },
    DMARC: {
      'not-found':
                'O registo DMARC não foi encontrado entre os registos TXT do subdomínio _dmarc.{domain}',
      'requested-domain':
                'Foi consultado o subdomínio <span class="text-white">_dmarc.{domain}</span>',
      'only-one-record-and-correct-format':
                'Deve existir apenas um registo com o formato correto',
      'policy-term-correctly-configured':
                'Termo de política corretamente configurado',
      'subdomain-policy-term-correctly-configured':
                'Termo de política de subdomínio corretamente configurado',
      'pct-term-correctly-configured':
                'Termo PCT corretamente configurado',
      'rua-and-ruf-term-correctly-configured':
                'Termos RUA e RUF corretamente configurados',
      'fo-term-correctly-configured':
                'Termo FO corretamente configurado',
      'adkim-term-correctly-configured':
                'Termo ADKIM corretamente configurado',
      'aspf-term-correctly-configured':
                'Termo ASPF corretamente configurado',
      info: {
        p: 'Especifica a política a seguir quando a validação falha',
        rua:
                    'URI ou URIs para enviar relatórios agregados da atividade de validação',
        ruf:
                    'URI ou URIs para enviar relatórios forenses de falhas de validação específicas',
        adkim:
                    'Especifica o nível de alinhamento necessário entre o DKIM e o domínio From',
        aspf:
                    'Especifica o nível de alinhamento necessário entre o SPF e o domínio From',
        fo:
                    'Indica o tipo de falha de validação necessária para gerar um relatório forense',
        pct: "Percentagem de aplicação das políticas 'quarantine' ou 'reject'",
        rf: 'Especifica o formato usado para os relatórios forenses enviados',
        ri:
                    'Intervalo de tempo (máximo) entre relatórios agregados, exigido pelos servidores destinatários',
        sp:
                    "Equivalente a 'p', mas aplicável apenas a subdomínios. Se não existir, aplica-se 'p'"
      }
    },
    CAA: {
      'accepted-certifying-bodies': 'Entidades certificadoras aceites',
      'accept-all-certifying-bodies-message': 'Não foram encontrados registos de restrição (com a tag \'issue\' ou \'issuewild\'), qualquer entidade certificadora é aceite',
      'deny-all-certifying-bodies-message': 'Com apenas um registo de restrição vazio (com a tag \'issue\' ou \'issuewild\'), qualquer entidade certificadora é restrita'
    },
    accesibility: {
      'alt-email-icon': 'Ícone de email',
      'aria-label-copy-btn': 'Copiar conteúdo do registo'
    },
    'what-is': "O que é '{key}'?"
  },
  'feedback-form': {
    title: 'Sugestões e relatório de erros',
    description: 'Pode enviar-nos sugestões ou avisar-nos de qualquer erro que tenha encontrado',
    'email-contact': 'Email de contacto',
    'email-place-holder': "example{'@'}example.com",
    'email-aria-label': 'Email de contacto para o formulário de sugestões e relatório de erros',
    'issue-description': 'Descrição da sugestão ou do erro encontrado',
    'issue-description-place-holder': 'Escreva a sua mensagem aqui',
    'description-aria-label': 'Descrição da sugestão ou do erro encontrado para o formulário de sugestões e relatório de erros',
    send: 'Enviar',
    'head-title': 'Sugestões e relatório de erros',
    'meta-description': 'Formulário para enviar sugestões e relatório de erros',
    'send-btn-aria-label': 'Botão de envio para o formulário de sugestões e relatório de erros',
    'go-to-feedback-btn-aria-label': 'Ir para o formulário de Sugestões e relatório de erros'
  }
}
